import React, { useState } from "react";
import classNames from "classnames";

import { useRefContext } from "../../contexts/RefProvider";
import { scrollTo, windowScrollTo } from "../../utils/clickHandlers";

import Tabs from "../../components/tabs/Tabs";
import Title from "../../components/title/Title";

import pricingParis from "../../assets/pricing-paris.png";
import pricingSuburb from "../../assets/pricing-suburb.png";

import "./Pricing.scss";

const ZONES = {
  paris: "paris",
  suburb: "suburb",
};

const Pricing = () => {
  const { pricingRef, contactRef, refTopPosition, windowInnerHeight } =
    useRefContext();
  const [selectedTab, setSelectedTab] = useState("paris");

  const isPageFitting = pricingRef?.current?.scrollHeight <= windowInnerHeight;

  const PricingContainerClass = classNames("Pricing-container", {
    "position-sticky-top": isPageFitting,
  });

  const handleClickOnTab = (tab) => {
    windowScrollTo(refTopPosition.pricing);
    if (selectedTab === tab) return;
    setSelectedTab(tab);
  };

  const tabs = [
    {
      key: ZONES.paris,
      text: "paris - paris",
      onClick: () => handleClickOnTab(ZONES.paris),
    },
    {
      key: ZONES.suburb,
      text: "paris - banlieue",
      onClick: () => handleClickOnTab(ZONES.suburb),
    },
  ];

  return (
    <section className={PricingContainerClass} ref={pricingRef}>
      <div className="Pricing-content">
        <div className="Pricing-title">
          <Title
            title="nos tarifs"
            subtitle={
              <span>
                Une tarification qui s'adapte en fonction de vos besoins. Vous
                avez un projet spécifique ? N'hésitez pas à &nbsp;
                <span className="link" onClick={scrollTo(contactRef)}>
                  nous contacter <small style={{ fontSize: 14 }}>↗</small>
                </span>
              </span>
            }
            color="var(--kargo-white)"
            centered
          />
        </div>
        <div className="Pricing-prices">
          <Tabs
            tabs={tabs}
            color="var(--kargo-white)"
            selectedTab={selectedTab}
          />
          <img
            src={selectedTab === ZONES.paris ? pricingParis : pricingSuburb}
            alt="Kargo - Coursier à vélo cargo"
            className="Pricing-price"
          />
        </div>
      </div>
    </section>
  );
};

export default Pricing;
