import { useEffect, useState } from "react";

import { useMenuContext } from "./contexts/MenuProvider";
import { useRefContext } from "./contexts/RefProvider";
import { useVideoLoadingContext } from "./contexts/VideoLoadingProvider";
import useDebouncedFunction from "./hooks/useDebouncedFunction";
import { openLink, scrollToTop, windowScrollTo } from "./utils/clickHandlers";
import { activateScroll, deactivateScroll } from "./utils/disableScroll";

import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Menu from "./components/menu/Menu";
import SplashScreen from "./components/splash-screen/SplashScreen";
import AboutUs from "./pages/about-us/AboutUs";
import HeroBanner from "./pages/hero-banner/HeroBanner";
import Pricing from "./pages/pricing/Pricing";
import Services from "./pages/services/Services";
import Zones from "./pages/zones/Zones";

import "./App.scss";
import "./styles/common.scss";

const ANIMATION_ENDING_TIME = 1200;

function offsetTop(ref) {
  return ref?.current?.offsetTop;
}

function App() {
  const { isMenuOpened } = useMenuContext();
  const {
    aboutUsRef,
    contactRef,
    heroBannerRef,
    pricingRef,
    servicesRef,
    zonesRef,
    refTopPosition,
    setRefTopPosition,
  } = useRefContext();
  const { isVideoLoading } = useVideoLoadingContext();

  const [isHeaderDark, setIsHeaderDark] = useState(true);

  const links = [
    {
      text: "Nos services",
      onClick: () => windowScrollTo(refTopPosition.services),
    },
    {
      text: "Nos zones et tarifs de livraison",
      onClick: () => windowScrollTo(refTopPosition.zones),
    },
    {
      text: "Notre histoire",
      onClick: () => windowScrollTo(refTopPosition.aboutUs),
    },
    {
      text: "Espace client",
      onClick: openLink("https://kargoservice.everst.io/client/login"),
    },
  ];

  const debouncedSetDarkHeader = useDebouncedFunction(() => {
    const scrollPosition = window.scrollY;
    const aboutUsTop = offsetTop(aboutUsRef);
    const servicesTop = offsetTop(servicesRef);
    const zonesTop = offsetTop(zonesRef);

    if (
      scrollPosition < servicesTop ||
      (scrollPosition >= zonesTop && scrollPosition < aboutUsTop)
    ) {
      setIsHeaderDark(true);
    } else {
      setIsHeaderDark(false);
    }
  }, 50);

  useEffect(() => {
    window.addEventListener("scroll", debouncedSetDarkHeader);
  }, [debouncedSetDarkHeader]);

  useEffect(() => {
    if (isVideoLoading) return;
    scrollToTop();
    deactivateScroll();
    setTimeout(() => {
      setRefTopPosition({
        aboutUs: offsetTop(aboutUsRef),
        contact: offsetTop(contactRef),
        heroBanner: offsetTop(heroBannerRef),
        pricing: offsetTop(pricingRef),
        services: offsetTop(servicesRef),
        zones: offsetTop(zonesRef),
      });
      activateScroll();
    }, ANIMATION_ENDING_TIME);
  }, [
    aboutUsRef,
    contactRef,
    heroBannerRef,
    isVideoLoading,
    pricingRef,
    servicesRef,
    setRefTopPosition,
    zonesRef,
  ]);

  return (
    <main className="App-container">
      {isVideoLoading && <SplashScreen />}
      {isMenuOpened && <Menu links={links} />}
      <Header links={links} darkHeader={isHeaderDark} />
      <HeroBanner />
      <Services />
      <Zones />
      <Pricing />
      <AboutUs />
      <Footer />
    </main>
  );
}

export default App;
