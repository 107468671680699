import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import { useRefContext } from "../../contexts/RefProvider";
import { useVideoLoadingContext } from "../../contexts/VideoLoadingProvider";
import { scrollTo } from "../../utils/clickHandlers";
import { useIsInViewport } from "../../utils/intersectionObserver";

import CardExplainer from "../../components/card-explainer/CardExplainer";
import Title from "../../components/title/Title";

import "./Services.scss";

const explainers = [
  {
    title: "livraison à la demande",
    text: "Livraison 100% écologique le jour même, dans le délai de votre choix, pour tout colis jusqu'à 60kg et 1m³",
  },
  {
    title: "solution web",
    text: "Un dashboard intuitif pour gérer vos livraisons et votre facturation",
  },
  {
    title: "logistique",
    text: "Nous facilitons votre partie logistique en gérant votre stock, vos commandes et vos envois",
  },
  {
    title: "e-commerce",
    text: "Grâce à notre API, l'interfaçage, et la personnalisation de vos envois sont simplifiés",
  },
];

const Services = () => {
  const { servicesRef, contactRef, windowInnerHeight } = useRefContext();
  const { isVideoLoading } = useVideoLoadingContext();

  const servicesCardsRef = useRef();
  const [isServicesCardsVisible, setIsServicesCardsVisible] = useState(false);

  const isServicesCardsReached = useIsInViewport(servicesCardsRef);

  const ServicesContainerClass = classNames("Services-container", {
    "Services-container-visible": isServicesCardsVisible,
    "position-sticky-top":
      servicesRef?.current?.scrollHeight <= windowInnerHeight,
  });

  const ServicesTitleClass = classNames("Services-title", {
    "Services-animation-1": isServicesCardsVisible,
  });

  const ServicesAnimationClass = (i) =>
    classNames({
      [`Services-card-animation-${i}`]: isServicesCardsVisible,
      [`Services-card-animation-${i}`]: isServicesCardsVisible,
      [`Services-card-animation-${i}`]: isServicesCardsVisible,
      [`Services-card-animation-${i}`]: isServicesCardsVisible,
    });

  useEffect(() => {
    if (isServicesCardsReached) {
      return setIsServicesCardsVisible(true);
    }
    return setIsServicesCardsVisible(false);
  }, [isVideoLoading, isServicesCardsReached]);

  return (
    <section className={ServicesContainerClass} ref={servicesRef}>
      <div className="Services-content">
        <div className={ServicesTitleClass}>
          <Title
            title="nos services"
            subtitle="Nos services sont assurés par une flotte de coursiers professionnels et expérimentés, tous équipés de leur propre vélo cargo."
            color="var(--kargo-black)"
          />
          <p>
            Une question ?&nbsp;
            <span className="link" onClick={scrollTo(contactRef)}>
              Contactez-nous <small style={{ fontSize: 14 }}>↗</small>
            </span>
          </p>
        </div>
        <div className="Services-cards" ref={servicesCardsRef}>
          {explainers.map((card, i) => (
            <div className={ServicesAnimationClass(i)} key={i}>
              <CardExplainer title={card.title} text={card.text} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
