function scrollToTop() {
  return window.scrollTo({ top: 0, left: 0 });
}

function scrollTo(ref) {
  return () => ref?.current?.scrollIntoView({ behavior: "smooth" });
}

function windowScrollTo(position) {
  if (window.scrollY === position) return;
  return window.scrollTo({
    top: position,
    left: 0,
    behavior: "smooth",
  });
}

function openLink(link) {
  return () => window.open(link, "_blank");
}

export { scrollToTop, scrollTo, windowScrollTo, openLink };
