import { createContext, useContext, useRef, useState } from "react";

const REF_CONTEXT = createContext(null);

const useRefContext = () => useContext(REF_CONTEXT);

const RefProvider = ({ children }) => {
  const [refTopPosition, setRefTopPosition] = useState({});

  const aboutUsRef = useRef(null);
  const contactRef = useRef(null);
  const heroBannerRef = useRef(null);
  const pricingRef = useRef(null);
  const servicesRef = useRef(null);
  const zonesRef = useRef(null);

  const windowInnerHeight = window.innerHeight;

  const value = {
    aboutUsRef,
    contactRef,
    heroBannerRef,
    pricingRef,
    servicesRef,
    zonesRef,
    windowInnerHeight,
    refTopPosition,
    setRefTopPosition,
  };

  return <REF_CONTEXT.Provider value={value}>{children}</REF_CONTEXT.Provider>;
};

export { RefProvider, useRefContext };
