import React, { useEffect, useState } from "react";
import classNames from "classnames";

import { useRefContext } from "../../contexts/RefProvider";
import { useVideoLoadingContext } from "../../contexts/VideoLoadingProvider";
import { useIsInViewport } from "../../utils/intersectionObserver";

import Title from "../../components/title/Title";

import cargo from "../../assets/cargo.svg";
import kg from "../../assets/kg.svg";
import time from "../../assets/time.svg";

import img1 from "../../assets/img-1.png";
import img2 from "../../assets/img-2.png";
import img3 from "../../assets/img-3.png";
import img4 from "../../assets/img-4.png";

import "./HeroBanner.scss";

const infos = [
  {
    logo: cargo,
    text: "100% vélo cargo",
  },
  {
    logo: kg,
    text: "Jusqu'à 60kg en instantanée (plus sur devis)",
  },
  {
    logo: time,
    text: "Livraison express en 1h",
  },
];

const images = [
  [img1, img2],
  [img3, img4],
];

const HeroBanner = () => {
  const { heroBannerRef, windowInnerHeight } = useRefContext();
  const { isVideoLoading } = useVideoLoadingContext();

  const [isHeroBannerVisible, setIsHeroBannerVisible] = useState(false);

  const isHeroBannerReached = useIsInViewport(heroBannerRef);

  const HeroBannerContainerClass = classNames("HeroBanner-container", {
    "position-sticky-top":
      heroBannerRef?.current?.scrollHeight <= windowInnerHeight,
  });

  const HeroBannerAnimationClass = classNames({
    "HeroBanner-image-animation-0": !isVideoLoading && isHeroBannerVisible,
    "HeroBanner-image-animation-1": !isVideoLoading && isHeroBannerVisible,
  });

  useEffect(() => {
    if (isHeroBannerReached) setIsHeroBannerVisible(true);
  }, [isHeroBannerReached]);

  return (
    <section className={HeroBannerContainerClass} ref={heroBannerRef}>
      <div className="HeroBanner-main">
        <h1 className="HeroBanner-title">
          <Title title="la livraison écologique au service du dernier kilomètre à Paris et proche banlieue" />
        </h1>
        <div className="HeroBanner-images">
          {images.map((image, index) => (
            <div className="HeroBanner-image-container" key={index}>
              {image.map((img, i) => (
                <img
                  src={img}
                  alt="Kargo - Coursier à vélo cargo"
                  className={HeroBannerAnimationClass}
                  width="100%"
                  key={i}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className="HeroBanner-infos">
        {infos.map((info) => (
          <div className="HeroBanner-info" key={info.text}>
            <img
              src={info.logo}
              alt={`Kargo - Coursier à vélo cargo - ${info.text}`}
              className="HeroBanner-info-logo"
            />
            <span>{info.text}</span>
          </div>
        ))}
      </div>
    </section>
  );
};

export default HeroBanner;
