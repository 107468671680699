import React from "react";
import classNames from "classnames";

import { openLink } from "../../utils/clickHandlers";

import Title from "../title/Title";

import aubade from "../../assets/partners/logo_espace_aubade.png";
import beneBono from "../../assets/partners/logo_benebono.png";
import bobsBakeShop from "../../assets/partners/logo_bobs_bake_shop.png";
import ecoOptic from "../../assets/partners/logo_eco_optic.png";
import lomi from "../../assets/partners/logo_lomi.png";

import "./Partners.scss";

const partners = [
  { name: "Aubade", logo: aubade, link: "https://www.espace-aubade.fr/" },
  { name: "Bene Bono", logo: beneBono, link: "https://benebono.fr/" },
  {
    name: "Bob's Bake Shop",
    logo: bobsBakeShop,
    link: "https://www.bobsjuicebar.com/",
  },
  {
    name: "Eco Optic",
    logo: ecoOptic,
    link: "https://www.ecocoursesoptic.fr/web/",
  },
  { name: "Lomi", logo: lomi, link: "https://lomi.cafe/", isDark: true },
];

const Partners = () => {
  const PartnersClass = (isDark) =>
    classNames("Partners-image", {
      "Partners-image-dark": isDark,
    });

  return (
    <section className="Partners-container">
      <Title
        title="ils nous font confiance"
        subtitle="Voici quelques entreprises avec lesquelles nous sommes fiers de collaborer chaque jour :"
        centered
      />
      <div className="Partners-list">
        {partners.map((partner, i) => (
          <img
            src={partner.logo}
            alt={`Kargo - Coursier à vélo cargo & ${partner.name}`}
            width="auto"
            height={40}
            onClick={openLink(partner.link)}
            className={PartnersClass(partner.isDark)}
            key={i}
          />
        ))}
      </div>
    </section>
  );
};

export default Partners;
