import { useCallback, useRef } from "react";

function useDebouncedFunction(callback, delay) {
  const timeoutRef = useRef();
  const debouncedFunction = useCallback(
    (...args) => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay]
  );
  return debouncedFunction;
}

export default useDebouncedFunction;
