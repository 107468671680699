import React from "react";
import classNames from "classnames";

import { useRefContext } from "../../contexts/RefProvider";

import Title from "../../components/title/Title";

import zonesMap from "../../assets/zones-map.png";

import "./Zones.scss";

const Zones = () => {
  const { zonesRef, windowInnerHeight } = useRefContext();

  const ZonesContainerClass = classNames("Zones-container", {
    "position-sticky-top": zonesRef?.current?.scrollHeight <= windowInnerHeight,
  });

  return (
    <section className={ZonesContainerClass} ref={zonesRef}>
      <div className="Zones-content">
        <Title
          title="nos zones de livraison"
          subtitle="Nous vous livrons en vélo-cargo dans Paris et en banlieue limitrophe"
          color="var(--kargo-white)"
          centered
        />
        <img
          src={zonesMap}
          alt="Kargo - Coursier à vélo cargo"
          className="Zones-image"
        />
      </div>
    </section>
  );
};

export default Zones;
